@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Text Regular'),
    url('./SF-Pro-Text-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  src: local('SF Pro Text Medium'),
    url('./SF-Pro-Text-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  src: local('SF Pro Text Semi Bold'),
    url('./SF-Pro-Text-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: bold;
  src: local('SF Pro Text Bold'),
    url('./SF-Pro-Text-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 900;
  src: local('SF Pro Text Heavy'),
    url('./SF-Pro-Text-Heavy.woff') format('woff');
}
